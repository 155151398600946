<template>
  <el-main>
    <div class="blodTitle">QQ小程序</div>
    <img src="../../assets/image/qq/banner.png" alt="" />
    <div class="blodTitle">授权及发布小程序</div>
    <QQempower></QQempower>
    <div class="blodTitle">五大核心优势</div>
    <div class="manyScene">
      <div v-for="(item, index) in fiveAdvantage" :key="index" class="manySceneItem">
        <img :src="item.img" alt="" />
        <div style="margin: 10px 0">{{ item.name }}</div>
        <div style="color: #666666">{{ item.desc }}</div>
      </div>
    </div>
    <div class="blodTitle">QQ小程序运营场景</div>
    <div class="manyScene">
      <div v-for="(item, index) in manyScene" :key="index" class="manySceneItem">
        <img :src="item.img" alt="" />
        <div style="margin: 20px 0 0 0; white-space: nowrap">{{ item.name }}</div>
      </div>
    </div>
    <div class="blodTitle">优秀案例</div>
    <div class="goodCase">
      <div class="goodCaseItem" v-for="(item, index) in goodCase" :key="index">
        <img :src="item.img" alt="" />
        <div>{{ item.name }}</div>
      </div>
    </div>
  </el-main>
</template>

<script>
import QQempower from './sonPage/qq/qqempower.vue'
export default {
  components: {
    QQempower
  },
  data() {
    return {
      fiveAdvantage: [
        { name: '流量红利', desc: '6 亿月活，海量年轻人聚集，流量红利待挖掘', img: require('@/assets/image/qq/01.png') },
        { name: 'QQ 群获客', desc: '海量活跃 QQ 群，搜索关键词，定向触达目标客群', img: require('@/assets/image/qq/02.png') },
        { name: '互动营销', desc: 'QQ 群 + QQ 空间 + 小程序，引爆下一个亿级消费市场', img: require('@/assets/image/qq/03.png') },
        { name: '极速成交变现', desc: '搭配 10+ 营销工具，激活购买力；匹配优质货源，快速变现', img: require('@/assets/image/qq/04.png') },
        { name: '冷启动扶持', desc: '依托专题推荐、群标签、排行等机制提供初始曝光资源', img: require('@/assets/image/qq/05.png') }
      ],
      manyScene: [
        { name: '首页下拉列表', img: require('@/assets/image/qq/pic01.png') },
        { name: '一键分享到QQ空间', img: require('@/assets/image/qq/pic02.png') },
        { name: 'qq群内嵌入小程序快捷入口', img: require('@/assets/image/qq/pic03.png') },
        { name: '小程序商店中心化分发入口', img: require('@/assets/image/qq/pic04.png') },
        { name: 'QQ广告投放', img: require('@/assets/image/qq/pic05.png') },
        { name: '从群应用、群橱窗等直接进入小程序', img: require('@/assets/image/qq/pic06.png') }
      ],
      goodCase: [
        { name: '雅兰天丽美妆', img: require('@/assets/image/qq/goodcase01.png') },
        { name: '天宜世家家居', img: require('@/assets/image/qq/goodcase02.png') },
        { name: '伊乐贝斯女装', img: require('@/assets/image/qq/goodcase03.png') },
        { name: '日易惠百货用品', img: require('@/assets/image/qq/goodcase04.png') },
        { name: '新稻香农产品', img: require('@/assets/image/qq/goodcase05.png') }
      ],
      otherConifg: [
        {
          img: require('@/assets/image/qq/dingyue.png'),
          title: '订阅消息',
          content:
            '一次性订阅：一次授权下发一条，可推送小程序/小游戏内最新活动/版本信息/回流活动等。长期订阅：小程序/小游戏累计用户数达到20w后自动开通该能力，用户授权同意后，小程序/小游戏每天可向用户下发一条社交互动类消息。系统订阅：一次授权，当有用户成功触发互动行为（排行超越、赠送礼物）时由平台下发消息'
        },
        {
          img: require('@/assets/image/qq/tiyanqrcode.png'),
          title: '体验小程序',
          content: '上传小程序后可生成体验码，可在手机上预览小程序。小程序未上线前，可以添加体验者微信号，即可手机浏览小程序。'
        }
      ]
    }
  },

  methods: {
    Jump(index) {
      if (index === 0) {
        // 订阅消息配置
      } else {
        // 获取体验码
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  background-color: #fff;
  .blodTitle {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin: 40px 0 20px 0;
    .subTitle {
      color: #5654f3;
      font-size: 14px;
      font-weight: normal;
      cursor: pointer;
      margin-left: 23px;
    }
  }
  & > img {
    width: 100%;
  }
  .manyScene {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 60px;
    .manySceneItem {
      flex: 0 0 200px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }
  .goodCase {
    display: flex;
    justify-content: space-between;
    // flex-wrap: nowrap;
    max-width: 70%;
    .goodCaseItem {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      text-align: center;
      flex-shrink: 0;
    }
  }
  .otherConfig {
    width: 100%;
    display: flex;
    .configItem {
      flex: 1;
      display: flex;
      align-items: center;
      border: 1px solid #ededed;
      border-radius: 2px;
      padding: 17px 20px;
      img {
        width: 50px;
        height: 50px;
      }
      .conTxt {
        margin-left: 20px;
        width: calc(100% - 65px);
        .top {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 4px;
        }
        .mid {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
        .bot {
          margin-top: 4px;
          span {
            color: #1676fe;
            cursor: pointer;
            font-size: 14px;
          }
        }
      }
    }
    .configItem:last-child {
      margin-left: 15px;
    }
  }
}
</style>
